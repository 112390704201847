.brain-img > svg > {display: block;}

.brain-img > svg > .programmer-brain {
  opacity: 90%;
  stroke: var(--secondary-color);
}
.brain-img > svg > .designer-brain {
  stroke: var(--variant-light-gold);
}


.brain-img > svg > .programmer-brain,
.brain-img > svg > .designer-brain {
  fill: none;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke-dasharray: 1900;
  stroke-dashoffset: 1900;
}
