:root {
  --greyDark: #2925248d;
  --greyLight-2: #29252463;
  --greyLight-3: #292524a4;
}

/*  CLOCK  */
.clock {
  width: 192px;
  height: 192px;
  display: flex;
  border-radius: 50%;
  position: relative;
  align-items: center;
  justify-self: center;
  justify-content: center;
  /* box-shadow: 0.3rem 0.3rem 0.6rem var(--primary-color),
    -0.2rem -0.2rem 0.5rem var(--generic-white); */
}

.clock .hand {
  bottom: 96px;
  z-index: 200;
  position: absolute;
  border-radius: 4px;
  transform-origin: bottom;
}

.clock .hours {
  width: 7px;
  height: 52px;
  background: var(--greyLight-3);
}

.clock .minutes {
  width: 6px;
  height: 74px;
  background: var(--greyDark);
}

.clock .seconds {
  width: 3px;
  height: 83px;
  background: var(--secondary-color);
}

.clock .point {
  width: 13px;
  height: 13px;
  z-index: 300;
  position: absolute;
  border-radius: 50%;
  background: var(--secondary-color);
  border: 2px solid var(--primary-color);
}

.clock .marker {
  width: 95%;
  height: 95%;
  border-radius: 50%;
  position: relative;
  box-shadow: inset 0.2rem 0.2rem 0.5rem var(--greyLight-2),
    inset -0.2rem -0.2rem 0.5rem var(--primary-color);
}

.clock .marker::after {
  top: 20%;
  left: 20%;
  width: 60%;
  content: "";
  height: 60%;
  filter: blur(1px);
  position: absolute;
  border-radius: 50%;
  box-shadow: inset 1px 1px 1px var(--greyLight-2),
    inset -1px -1px 1px var(--primary-color);
}