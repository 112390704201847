/* nav link */
[data-attr="navbar"] .nav-selection {
  z-index: 1;
  width: 90px;
  height: 40px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}

[data-attr="navbar"] .nav-selection:hover {
  cursor: none;
  transition: color 0.4s ease;
  color: var(--secondary-color);
}

[data-attr="navbar"] .type-1:after {
  left: 0;
  bottom: 0;
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  position: absolute;
  transform: scaleX(0);
  transform-origin: 100%;
  transition: transform 0.4s ease;
  border-bottom: 2px solid var(--secondary-color);
}

[data-attr="navbar"] .type-1:hover:after {
  transform-origin: 0%;
  transform: scaleX(1);
}

/* Active Route Styles */
[data-attr="navbar"] .active-path{
  transition: border 350ms ease-in;
  border-bottom: 2px solid var(--secondary-color);
}

[data-attr="navbar"] .active-path:hover{
  transition: border 350ms ease-in;
  border-bottom: 1px solid transparent;
}

@media (max-width: 640px) {
  [data-attr="navbar"] .nav-selection {width: 64px;}

  [data-attr="navbar"] .nav-selection:hover {
    color: var(--variant-gold);
  }

  [data-attr="navbar"] .type-1:after {
    border-bottom: 2px solid var(--variant-gold);
  }

  [data-attr="navbar"] .active-path{
    border-bottom: 2px solid var(--variant-gold);
  }
}