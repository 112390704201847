[data-attr="home-page"] {
  width: 100%;
  height: 682px;
  user-select: none;
}

[data-attr="home-page"] > .content-wrapper {
  margin: auto;
  position: relative;
  width:calc(100% - 28px);
  height: calc(100% - 164px);
}
/* 
.content-wrapper > [data-attr="nemo"] {
  top: 12px;
  user-select: none;
  position: absolute;
}

.content-wrapper > [data-attr="nemo"] > h1 {
  font-size: 26px;
  font-weight: 600;
} */

.content-wrapper > .clock-wrapper {
  left: 0;
  right: 0;
  top: 71px;
  margin: auto;
  position: absolute;
  width: fit-content;
  height: fit-content;
}

.content-wrapper > .info-wrapper {
  bottom: -120px;
  width: 100%;
  gap: 48px;
  display: flex;
  position: absolute;
  align-items: center;
  flex-direction: column;
}

.content-wrapper > .info-wrapper > .name-box > h1 {
  font-size: 40px;
  font-weight: 600;
  line-height: 64px;
  white-space: nowrap;
  position: relative;
}

.content-wrapper > .info-wrapper > .name-box > h1::after {
  bottom: 6px;
  content: ".";
  font-size: 64px;
  position: absolute;
  color: var(--variant-gold);
}

.content-wrapper > .info-wrapper > .name-box > p {
  font-size: 14px;
  line-height: 19px;
}

.content-wrapper > .info-wrapper > .name-box > p > span {
  gap: 4px;
  display: flex;
  margin: 3px 0;
  align-items: center;
}

.content-wrapper > .info-wrapper > .name-box > p > a {
  /* font-weight: 700; */
  text-decoration: none;
  /* color: var(--secondary-color); */
}

.content-wrapper > .info-wrapper > .social-section{width: fit-content;}
.color{	fill: var(--secondary-color); transition: all 350ms ease;}

.animate-arrow { animation: animate 1.6s ease infinite;}
@keyframes animate {
  0% {transform: translateY(0%);}
  50% {transform: translateY(10%);}
  100% {transform: translateY(0%);}
}


@media screen and (min-width: 640px) {
  [data-attr="home-page"] {margin: unset; height: 664px;}
  [data-attr="home-page"] > .content-wrapper { height: calc(100% - 122px);}

  /* .content-wrapper > [data-attr="nemo"] {left: 72px;}
  .content-wrapper > [data-attr="nemo"] > h1 {font-size: 38px;} */

  .content-wrapper > .clock-wrapper {
    top: 89px;
    left: 85px;
    margin: unset;
  }

  .content-wrapper > .info-wrapper > .name-box > h1 {
    font-size: 64px;
    line-height: 72px;
  }

  .content-wrapper > .info-wrapper > .name-box > h1::after {
    font-size: 96px;
  }

  .content-wrapper > .info-wrapper {
    bottom: 20px;
    padding: 0 48px;
    align-items: start;
  }

  .content-wrapper > .info-wrapper > .social-section{
    top: -316px;
    right: 20px;
    width: fit-content;
    position: absolute;
  }
}

@media screen and (min-width: 1024px) {
  [data-attr="home-page"] {height: 700px;}
  /* .content-wrapper > [data-attr="nemo"] {left: unset; right: 196px;}
  .content-wrapper > [data-attr="nemo"] > h1 {font-size: 42px;} */

  .content-wrapper > .clock-wrapper {
    top: 90px;
    left: 116px;
    margin: unset;
  }

  .content-wrapper > .info-wrapper {
    left: 0;
    right: 0;
    bottom: 20px;
    width: 90%;
    margin: 0 auto;
    align-items: end;
    flex-direction: row;
    justify-content: space-between;
  }

  .content-wrapper > .info-wrapper > .name-box > h1 {
    font-size: 72px;
    line-height: 88px;
    position: relative;
    margin-bottom: 6px;
    letter-spacing: 1.5px;
  }

  .content-wrapper > .info-wrapper > .name-box > h1::after {
    content: ".";
    font-size: 128px;
    bottom: 12px;
    /* position: absolute; */
  }

  .content-wrapper > .info-wrapper > .name-box > p {
    font-size: 17px;
    font-weight: 400;
    line-height: 23px;
  }

  .content-wrapper > .info-wrapper > .social-section{
    position: unset;
    width: fit-content;
  }
}

@media screen and (min-width: 1280px) {
  .content-wrapper > .info-wrapper > .name-box > h1 {
    font-size: 88px;
    line-height: 92px;
  }

  .content-wrapper > .info-wrapper > .name-box > h1::after {
    font-size: 148px;
  }
}