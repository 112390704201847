@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Sacramento&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Yatra+One&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Cinzel+Decorative:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Style+Script&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

/* -------font-------- */
.sacramento {font-family: "Sacramento", cursive;}
.yatra {font-family: "Yatra One", cursive;}
.cinzel{font-family: 'Cinzel Decorative', serif;}
.signature {font-family: "Style Script", cursive;}

/* -------------------- */
:root {
  --generic-white: #ffffff;
  /* --primary-color: #ede5de; */
  --primary-color: #F2F7F4;
  --secondary-color: #292524;
  --variant-gold: #ca8a04;
  --variant-light-gold: #daa523;
  --text-color: #292524;
}

.buymeacoffee-btn {
  height: 48px !important;
  width: 196px !important;
}
.bdr {border: 1px solid red;}

#custom-cursor {
  top: 0;
  width: 20px;
  height: 20px;
  display: none;
  z-index: 100000;
  position: absolute;
  border-radius: 12px;
  border: 2px solid var(--secondary-color);
  /* clip-path: polygon(0 0, 0 0, 90% 61%, 55% 75%, 32% 100%, 30% 100%); */
}

#custom-cursor::after {
  inset: 0;
  width: 4px;
  content: "";
  height: 4px;
  margin: auto;
  position: absolute;
  border-radius: 2px;
  background-color: var(--secondary-color);
  
}

html {
  cursor: none;
  scroll-behavior: smooth;
  -webkit-tap-highlight-color: transparent;
}

body {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  background-size: 100%;
  background-position-y: 19%;
  color: var(--secondary-color);
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position-x: center;
  font-family: "Quicksand", sans-serif;
  background-color: var(--primary-color);
  background-image: url("./Assets/hero-bg.png");
  /* transform: scale(1); */
}

@media screen and (max-width: 640px) {
  body {
    background-size: 200%;
    background-position-x: 10%;
  }
}

.width {max-width: 1580px;}

/* scroll bar hide */
::-webkit-scrollbar {width: 0px;}
::-webkit-scrollbar-thumb {
  background-color: #292524;
  border-radius: 5px;
}
::-webkit-scrollbar-track {background-color: var(--primary-color);}

/* for selected color */
::selection {
  background-color: #d39d15;
  color: #292524;
}

img,
button {
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
a, button {cursor: none;}

.scroll-btn-design {
  width: 22px;
  height: 34px;
  position: relative;
  border-radius: 20px;
  border: 2px solid var(--secondary-color);
}

.scroll-btn-design::before {
  inset: 0;
  width: 2px;
  content: "";
  height: 6px;
  margin: auto;
  border-radius: 2px;
  position: absolute;
  transition: all 500ms ease;
  background-color: var(--secondary-color);
  animation: moving 2s ease-in-out infinite;
}

@media screen and (max-width: 640px) {
  .scroll-btn-design {
    width: 20px;
    height: 30px;
  }

  .scroll-btn-design::before {
    width: 1.5px;
    height: 4px;
  }
}

.scroll-btn:hover .scroll-btn-design::before {
  transform: translateY(-4px); animation: none;
}

@keyframes moving {
  0% {transform: translateY(-2px);}
  50% {transform: translateY(-4px);}
  100% {transform: translateY(-2px);}
}

[data-attr="nemo"] {user-select: none;}
[data-attr="nemo"] {
  top: 12px;
  user-select: none;
  position: absolute;
}

[data-attr="nemo"] > h1 {
  font-size: 26px;
  font-weight: 600;
}

/* --------  Page Styles --------------- */
[data-attr="posts-page"],
[data-attr=portfolio-page] {height: 100vh;}

[data-attr="posts-page"][data-page="true"],
[data-attr=portfolio-page][data-page="true"] {height: 100%;}

[data-attr="home-page"] .content-wrapper,
[data-attr="posts-page"] .content-wrapper,
[data-attr="contact-page"] .content-wrapper,
[data-attr=portfolio-page] .content-wrapper {
  opacity: 0;
  display: none;
}

[data-attr="home-page"][data-page="true"] > .content-wrapper,
[data-attr="posts-page"][data-page="true"] > .content-wrapper,
[data-attr="contact-page"][data-page="true"] > .content-wrapper,
[data-attr=portfolio-page][data-page="true"] > .content-wrapper {
  opacity: 1;
  display: block;
  transition: all 1s ease 400ms;
}
[data-attr="contact-page"][data-page="true"] > .content-wrapper {display: flex;}

/* Starting Animation */
#starting-animation{ opacity: 1;}

#starting-animation[data-starting-animation="false"]{ 
  animation: animation-hide 600ms ease-out forwards;
}

@keyframes animation-hide {
  0% { opacity: 1; }
  90% {opacity: 0;}
  100% {opacity: 0; display: none;}
}

/* ----------- Others Styles ------- */

/* details btn */
.dtl-selection-link,
.dtl-selection {
  z-index: 1;
  width: 100%;
  height: 42px;
  padding: 8px;
  display: flex;
  position: relative;
  align-items: center;
  letter-spacing: 3px;
  justify-content: end;
  border-top: 1px solid #292524;
}
.dtl-selection-link {justify-content: center;}

.dtl-selection-link:hover,
.dtl-selection:hover {
  transition: color 0.4s ease;
  color: #daa520;
}

.type-3:after {
  right: 0;
  bottom: 0;
  z-index: -1;
  width: 100%;
  content: "";
  height: 100%;
  position: absolute;
  transform: scaleX(0);
  background: #292524;
  transform-origin: 100%;
  transition: transform 0.4s ease;
}

.type-3:hover:after { transform: scaleX(1);}


/* bounce  Y*/
.bounce { animation: bounce 2s infinite;}

.bounce:hover { animation: none;}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
  40% {transform: translateY(-15px);}
  60% {transform: translateY(-10px);}
}


/* Typing Animation */