/* [data-attr="nemo"] {user-select: none;}

[data-attr="nemo"] > h1 {
  font-size: 26px;
  font-weight: 600;
} */

.text-box::before {
  left: 0;
  right: 0;
  width: 0%;
  bottom: 0;
  content: "";
  z-index: -1;
  margin: auto;
  height: 1.5px;
  position: absolute;
  transform-origin: center;
  transition: all 500ms ease;
  background-color: var(--secondary-color);
}

.post-card:hover .text-box::before {width: 100%; transition: all 750ms ease;}
.post-card:hover .author { right: 12px; transition: all 500ms ease;}
.post-card:hover .date {padding: 16px 8px 0 8px; transition: all 500ms ease;}
