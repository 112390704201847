.opacity { opacity: 1; }

[data-attr="strategy"] .title-wrapper {
  width: fit-content;
  margin: 0 auto;
}

[data-attr="strategy"] .title-wrapper > .title{
  font-size: 32px;
  line-height: 36px;
}
[data-attr="strategy"] .title-wrapper > .title > .first-letter {
  font-size: 52px;
  line-height: 56px;
  color: var(--variant-gold);
}

[data-attr="strategy"] .point-wrapper > .single-point {
  margin: 42px 0;
  max-width: 100%;
  box-sizing: border-box;
}

[data-attr="strategy"] .point-wrapper > .single-point > .serial-number {
  width: 28px;
  height: 28px;
  padding: 4px;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  border-radius: 50%;
  text-align: center;
  margin-bottom: 8px;
  box-sizing: border-box;
  border: 1.7px solid var(--secondary-color);
}
[data-attr="strategy"] .point-wrapper > .single-point > .text {
  font-size: 15px;
  line-height: 20px;
}

@media screen and (min-width: 640px) {
  [data-attr="strategy"] .point-wrapper > .single-point > .text {
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (min-width: 840px) {
  .opacity { opacity: 0.15; }

  [data-attr="strategy"] .title-wrapper {
    inset: 0;
    margin: auto;
    position: absolute;
    width: fit-content;
    height: fit-content;
    /* opacity: 0.10; */
  }
  
  [data-attr="strategy"] .title-wrapper > .title{
    font-size: 96px;
    font-weight: 700;
    line-height: 100px;
  }
  [data-attr="strategy"] .title-wrapper > .title > .first-letter {
    font-size: 148px;
    line-height: 152px;
  }
  

  [data-attr="strategy"] .point-wrapper > .single-point {
    max-width: 48%;
    margin: 32px 0;
  }

  [data-attr="strategy"] .point-wrapper > .single-point:nth-child(even) {
    margin-left: auto;
  }
}