[data-attr="projects"] {
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  position: relative;
  padding: 64px 12px;
}

[data-attr="projects"]::before{
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  opacity: 75%;
  position: absolute;
}

@media (min-width: 1300px) {
  [data-attr="projects"] {padding: 128px 0;}
}