@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.min.css);


.rabbit {
  width: 5em;
  z-index: 1;
  height: 3em;
  position: relative;
  border-radius: 70% 90% 60% 50%;
  background: var(--generic-white);
  animation: hop 1s infinite linear;
  box-shadow: -0.2em 1em 0 -0.75em #b78e81;
  transform: rotate(0deg) translate(-2em, 0);
}

.rabbit:before {
  top: 0.5em;
  width: 1em;
  height: 1em;
  content: "";
  left: -0.3em;
  position: absolute;
  border-radius: 100%;
  animation: kick 1s infinite linear;
  box-shadow: 4em 0.4em 0 -0.35em #3f3334, 
              0.5em 1em 0 var(--generic-white), 
              4em 1em 0 -0.3em var(--generic-white), 
              4em 1em 0 -0.3em var(--generic-white), 
              4em 1em 0 -0.4em var(--generic-white);
}

.rabbit:after {
  top: -1em;
  right: 1em;
  height: 2em;
  content: "";
  width: .75em;
  position: absolute;
  transform: rotate(-30deg);
  border-radius: 50% 100% 0 0;
  border-top: 1px solid #f7f5f4;
  border-left: 1px solid #f7f5f4;
  background: var(--generic-white);
  box-shadow: -0.5em 0em 0 -0.1em var(--generic-white);
}

.clouds {
  top: -5em;
  width: 2em;
  opacity: 0;
  height: 2em;
  position: relative;
  transform: translate(0, 0);
  border-radius: 100% 100% 0 0;
  -ms-transform: translate(0, 0);
  -moz-transform: translate(0, 0);
  background: var(--generic-white);
  -webkit-transform: translate(0, 0);
  animation: cloudy 1s infinite linear forwards;
  box-shadow: 5em 2em 0 -0.3em var(--generic-white),
              -2em 2em 0 0 var(--generic-white);
}

.clouds:before, .clouds:after {
  content: '';
  position: absolute;
  box-shadow: 5em 2em 0 -0.3em var(--generic-white),
              -2em 2em 0 var(--generic-white);
}

.clouds:before {
  bottom: 0;
  left: -30%;
  width: 1.25em;
  height: 1.25em;
  border-radius: 100% 100% 0 100%;
  background: var(--generic-white);
}

.clouds:after {
  bottom: 0;
  right: -30%;
  width: 1.5em;
  height: 1.5em;
  border-radius: 100% 100% 100% 0;
  background: var(--generic-white);
}

@keyframes hop {
  20% {
    transform: rotate(-10deg) translate(1em, -2em);
    box-shadow: -0.2em 3em 0 -1em #b78e81;
  }
  40% {
    transform: rotate(10deg) translate(3em, -4em);
    box-shadow: -0.2em 3.25em 0 -1.1em #b78e81;
  }
  60%,75% {
    transform: rotate(0) translate(4em, 0);
    box-shadow: -0.2em 1em 0 -0.75em #b78e81;
  }
}

@keyframes kick {
  20%,50% {
    box-shadow: 4em 0.4em 0 -0.35em #3f3334,
                0.5em 1.5em 0 var(--generic-white),
                4em 1.75em 0 -0.3em var(--generic-white),
                4em 1.75em 0 -0.3em var(--generic-white),
                4em 1.9em 0 -0.4em var(--generic-white);
  }
  40% {
    box-shadow: 4em 0.4em 0 -0.35em #3f3334,
                0.5em 2em 0 var(--generic-white),
                4em 1.75em 0 -0.3em var(--generic-white),
                4.2em 1.75em 0 -0.2em var(--generic-white),
                4.4em 1.9em 0 -0.2em var(--generic-white);
  }
}

@keyframes cloudy {
  40% {
    opacity: 0.75;
    transform: translate(-3em, 0);
  }
  55% {
    opacity: 0;
    transform: translate(-4em, 0);
  }
  90% {
    transform: translate(0, 0);
  }
}
