/* #about-text {background: var(--primary-color);} */
.bg-ast {position: relative;}
.bg-ast::before{
  content: "";
  top: -120px;
  z-index: -1;
  /* left: 60px; */
  position: absolute;
  width: 300px;
  height: 300px;
  background-repeat: no-repeat;
  background-size: 100%;
  background-image: url("../../../Assets/ast-one.png");
}

@media (max-width: 768px) {
  .bg-ast::before{display: none;}

  /* #about-text {
    backdrop-filter: unset;
    background: transparent;
  } */
}